<template>
  <div>
    <v-breadcrumbs :items="crumbs"></v-breadcrumbs>
    <base-loader :isLoading="isLoading"></base-loader>
    <v-row>
      <v-col md="6">
        <v-card>
          <v-card-text>
            <h4 class="text-md-h4 primary--text mb-5">Document Details</h4>
            <v-simple-table v-if="$route.params.id != 0">
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>
                    {{ documentModel.name ? documentModel.name : "" }}
                  </td>
                </tr>
                <tr>
                  <td>Document Class</td>
                  <td>
                    <v-chip
                      label
                      dark
                      :color="
                        documentModel.docClass == 'personal'
                          ? 'primary'
                          : documentModel.docClass == 'business'
                          ? 'secondary'
                          : 'accent'
                      "
                      >{{ documentModel.docClass.toUpperCase() }}</v-chip
                    >
                  </td>
                </tr>
                <tr>
                  <td>Category</td>
                  <td>
                    {{ documentModel.category ? documentModel.category : "" }}
                  </td>
                </tr>
                <tr>
                  <td>Document Link</td>
                  <td>
                    {{
                      documentModel.documentLink
                        ? documentModel.documentLink
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Document File Name</td>
                  <td>
                    {{
                      documentModel.documentFileName
                        ? documentModel.documentFileName
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <td>CreatedBy</td>
                  <td>
                    {{ documentModel.createdBy ? documentModel.createdBy : "" }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <v-btn
              v-if="$route.params.id == 0"
              color="primary"
              :to="`${$route.path}/edit`"
              class="mr-2"
              >Create a New Document</v-btn
            >
            <v-btn
              v-else
              color="primary"
              :to="`${$route.path}/edit`"
              class="mr-2"
              >Edit</v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
export default {
  data() {
    return {
      isEdit: false,
      isLoading: false,
      documentModel: new Document(),
      me: "",
      search: null,
      crumbs: [
        {
          text: "Documents",
          disabled: false,
          href: "/user/documents",
        },
        {
          text: "Document Details",
          disabled: true,
          href: `/user/document/${this.$route.params.id}`,
        },
      ],
    };
  },
  created() {},
  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,
    document: {
      query: gql`
        query Document($id: ID!) {
          document(id: $id) {
            id
            name
            user
            docClass
            category
            documentLink
            documentFileName
            createdBy
            createdAt
            updatedAt
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.$route.params.id || this.$route.params.id == 0;
      },
      variables() {
        const self = this;
        return {
          id: self.$route.params.id,
        };
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
      },
      result(response, key) {
        console.log("got data");
        this.isLoading = false;
        this.documentModel.id = this.document.id;
        this.documentModel.name = this.document.name;
        this.documentModel.user = this.document.user;
        this.documentModel.docClass = this.document.docClass;
        this.documentModel.category = this.document.category;
        this.documentModel.documentLink = this.document.documentLink;
        this.documentModel.documentFileName = this.document.documentFileName;
        this.documentModel.createdBy = this.document.createdBy;
      },
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
